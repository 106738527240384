import React, { Component } from "react";
import "url-search-params-polyfill";
import { Switch, Route, withRouter } from "react-router-dom";
import Alert from "@mui/material/Alert";
import "bootstrap/dist/css/bootstrap.css";
import "./Root.scss";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import PageLayout from "../../components/PageLayout/PageLayout";
import Cookies from "js-cookie";

import incentivesScreenshot from "../../assets/images/metaImages/ev-incentives.jpg";
import Incentives from "../../../pages/Incentives/Incentives";
import FourOhFour from "../../../pages/FourOhFour/FourOhFour";

// Services
import fetchWrapper from "../../../utils/fetch/fetchWrapper";
import fetchElectricVehicles from "../../../services/fetchElectricVehicles";
import fetchGasolineVehicles from "../../../services/fetchGasolineVehicles";
import fetchIncentives from "../../../services/fetchIncentives";
import fetchVehicleIncentivesWithHandle from "../../../services/fetchVehicleIncentivesWithHandle";
import fetchHomeChargers from "../../../services/fetchHomeChargers";
import fetchGreencars from "../../../services/fetchGreenCars";

// Utilities
import Uuid from "../../../utils/Uuid/Uuid";
import {
  loadState,
  persistState,
} from "../../../utils/LocalStorage/LocalStorage";
import loadUserPrefs from "../../../context/UserPrefs/loadUserPrefs";
import getUserPref from "../../../context/UserPrefs/getUserPref";
import USER_PREF_PRESETS from "../../../context/UserPrefs/USER_PREF_PRESETS";
import GaTracker from "../../../utils/GaTracker/GaTracker";
import isIE from "../../../utils/isIE";

// Data
import { DEALERS } from "../../data/dealers/DEALERS";
import { VALID_CHARGING_STATIONS } from "../../data/VALID_CHARGING_STATIONS";

class Root extends Component {
  constructor(props) {
    super(props);

    const existingState = loadState() || {};
    const savedPrefs =
      existingState && existingState.userPreferences
        ? existingState.userPreferences
        : {};

    if (!savedPrefs.vehicleFormFactorFilters)
      savedPrefs.vehicleFormFactorFilters = {};
    if (!savedPrefs.vehicleFuelTypeFilters)
      savedPrefs.vehicleFuelTypeFilters = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};
    if (!savedPrefs.chargerFormFactorFilters)
      savedPrefs.chargerFormFactorFilters = {};
    if (!savedPrefs.chargerWifiFilter) savedPrefs.chargerWifiFilter = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};

    this.state = {
      uuid: existingState.uuid || Uuid(),
      errors: [],
      ipData: existingState.ipData || null,
      electricVehicles: null,
      usedElectricVehicles: null,
      upcomingElectricVehicles: null,
      gasolineVehicles: null,
      incentives: null,
      incentivePrefsModalIsOpen: false,
      userLocation: null,
      userLocationNotFound: false,
      userLocationDealersNotFound: false,
      zipcodeUpdating: false,
      userPreferences: loadUserPrefs(savedPrefs),
      homeChargers: null,
      greenCars: null,
    };
    // Last resort to ensure that the user has a UUID
    if (!this.state.uuid) this.state.uuid = Uuid();
    this.getUserZip = this.getUserZip.bind(this);
    this.loadElectricVehicleData = this.loadElectricVehicleData.bind(this);
    this.loadGasolineVehicleData = this.loadGasolineVehicleData.bind(this);
    this.loadGreenCarsData = this.loadGreenCarsData.bind(this);
    this.loadIncentivesData = this.loadIncentivesData.bind(this);
    this.updateUserPreferences = this.updateUserPreferences.bind(this);
    this.incentivePrefsModalToggle = this.incentivePrefsModalToggle.bind(this);
    this.loadDealers = this.loadDealers.bind(this);
    this.loadZipcodeData = this.loadZipcodeData.bind(this);
  }

  componentDidMount() {
    this.loadElectricVehicleData();
    this.loadGasolineVehicleData();
    this.loadGreenCarsData();
    this.loadZipcodeData();
    this.loadIncentivesData();
    this.loadDealers();
    this.loadHomeChargers();

    GaTracker.initialize();
    const page = this.props.location.pathname + this.props.location.search;
    GaTracker.trackPage(page, { userId: this.state.uuid });
    this.getUserZip();
  }

  // TODO: this should be cleaner
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.userPreferences.zipcode !==
        prevState.userPreferences.zipcode ||
      this.state.userPreferences.householdSize !==
        prevState.userPreferences.householdSize ||
      this.state.userPreferences.householdIncome !==
        prevState.userPreferences.householdIncome
    ) {
      this.loadElectricVehicleData();
      this.loadGreenCarsData();
      this.loadIncentivesData();
    } else if (
      this.state.userPreferences.vehicleIdForIncentives !==
        prevState.userPreferences.vehicleIdForIncentives ||
      this.state.userPreferences.vehicleHandleForIncentives !==
        prevState.userPreferences.vehicleHandleForIncentives ||
        this.state.userPreferences.vehicleBoughtAs !==
        prevState.userPreferences.vehicleBoughtAs ||
        this.state.userPreferences.vehiclePurchasePrice !==
        prevState.userPreferences.vehiclePurchasePrice
    ) {
      this.loadIncentivesData();
    } else if(this.state.userPreferences.vehicleYearFilter !==
       prevState.userPreferences.vehicleYearFilter) {
              this.loadElectricVehicleData();
              this.loadIncentivesData();
       }

    if (
      this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode
    ) {
      this.loadZipcodeData();
    }
    let prevZip = prevState.userLocation
      ? prevState.userLocation.postcode
      : null;
    if (
      this.state.userLocation &&
      this.state.userLocation.postcode !== prevZip
    ) {
      this.loadDealers();
    }

    if (this.state.userLocation !== prevState.userLocation) {
      this.loadChargingStations();
    }

    if (!this.state.userLocationNotFound && !this.state.zipcodeUpdating) {
      persistState(this.state);
    }

    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const nextPage = this.props.location.pathname + this.props.location.search;

    if (currentPage !== nextPage) {
      GaTracker.trackPage(nextPage, { userId: this.state.uuid });
    }
  }

  getUserZip() {
    let target = "post-code";
    let match = Cookies.get(target);

    if (match) {
      return this.updateUserPreferences({ zipcode: match });
    }
  }

  async loadElectricVehicleData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref(
        "householdIncome",
        this.state.userPreferences
      ),
      model_year: getUserPref("vehicleYearFilter", this.state.userPreferences),
    };

    try {
      const electricVehicles = await fetchElectricVehicles(params);
      if (!electricVehicles) return;
      this.setState({
        electricVehicles: electricVehicles.newElectricVehicles,
        usedElectricVehicles: electricVehicles.usedElectricVehicles,
        upcomingElectricVehicles: electricVehicles.upcomingElectricVehicles,
      });
      this.setState({ errors: [] });
    } catch (error) {
      this.setState({ errors: [...this.state.errors, error] });
    }
  }
  async loadGasolineVehicleData() {
    let params = {
      fuel_type: "gas",
      postcode: getUserPref("zipcode", this.state.userPreferences),
      model_year: getUserPref("vehicleYearFilter", this.state.userPreferences),
    };

    try {
      const gasolineVehicles = await fetchGasolineVehicles(params);
      if (!gasolineVehicles) return;
      this.setState({ gasolineVehicles, errors: [] });
    } catch (error) {
      this.setState({ errors: [...this.state.errors, error] });
    }
  }
  async loadGreenCarsData() {
    const greenCarsTakes = {};
    let token;

    try {
      while (token !== null) {
        let data = await fetchGreencars(token);
        data.listGreencarsTakes.items.forEach((take) => {
          greenCarsTakes[take.vehicle_handle] = take.greencars_take;
        });
        token = data.listGreencarsTakes.nextToken;
      }

      this.setState({ greenCars: greenCarsTakes, errors: [] });
    } catch (error) {
      this.setState({ errors: [...this.state.errors, error] });
    }
  }

       async loadIncentivesData() {
              const queryParams = new URLSearchParams(window.location.search);
              const language = queryParams.get('lang');
              let locationParams = {
                     postcode: getUserPref("zipcode", this.state.userPreferences),
                     lang: language || '',
              }
              let params = {
                     vehicle_handle: getUserPref(
                            "vehicleHandleForIncentives",
                            this.state.userPreferences
                     ),
                     purchase_price: getUserPref("vehiclePurchasePrice", this.state.userPreferences),
                     household_size: getUserPref("householdSize", this.state.userPreferences),
                     household_income: getUserPref(
                            "householdIncome",
                            this.state.userPreferences
                     ),
                     turn_in_clunker: getUserPref("canTurnInClunker", this.state.userPreferences),
                     treat_as: getUserPref("vehicleBoughtAs", this.state.userPreferences) || 'New',
              };

              try {
                     const incentives =
                            params["vehicle_handle"] !== null
                                   ? await fetchVehicleIncentivesWithHandle({...locationParams, ...params})
                            : await fetchIncentives(locationParams);
                     if (!incentives) return;
                     this.setState({ incentives, errors: [] });
              } catch (error) {
                     console.log(error);
              }
       }

  async loadHomeChargers() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
    };
    try {
      const homeChargers = await fetchHomeChargers(params);
      if (!homeChargers) return;
      this.setState({ homeChargers, errors: [] });
    } catch (error) {
      this.setState({ errors: [...this.state.errors, error] });
    }
  }

  loadChargingStations() {
    // let zips =
    //   this.state.userLocation &&
    //     this.state.userLocation.nearby_postcodes &&
    //     this.state.userLocation.nearby_postcodes.length
    //     ? this.state.userLocation.nearby_postcodes.join(",")
    //     : getUserPref("zipcode", this.state.userPreferences);
    let params = {
      fuel_type: "ELEC",
      format: "JSON",
      // zip: zips,
      // NREL API doesn't handle preflight CORS requests properly, so we have to
      // include the API key as a GET query param instead of as a header
      api_key: process.env.REACT_APP_NREL_API_KEY,
    };
    let url = new URL(
      "https://developer.nrel.gov/api/alt-fuel-stations/v1.json"
    );

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    window.fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    window
      .fetch(url)
      .then((response) => response.json())
      .then((data) => {
        // let chargingStations = data.fuel_stations.concat(DEALERS);
        let chargingStations = data.fuel_stations.concat(
          VALID_CHARGING_STATIONS.map((v) => ({
            ...v,
            access_code: "level-2",
            ev_dc_fast_num: null,
            exclude_clusters: true,
          }))
        );
        this.setState({ chargingStations: chargingStations });
      });
  }

  loadDealers() {
    if (!process.env.REACT_APP_PAGES_DEALERS_ENABLED) {
      return;
    }

    this.setState({ dealerLocations: DEALERS });

    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      distance: isIE() ? 25 : 100,
    };
    let url = new URL(`${process.env.REACT_APP_API_HOST}/dealers`);

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;
    fetchWrapper(url, {
      method: "GET",
    })
      .then((data) => {
        if (data.dealers) {
          this.setState({
            dealerLocations: data.dealers,
            userLocationDealersNotFound: false,
          });
        } else {
          this.setState({
            userLocationDealersNotFound: true,
            zipcodeIsUpdating: false
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  loadZipcodeData() {
    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      distance: 0,
    };
    let url = new URL(`${process.env.REACT_APP_API_HOST}/location`);
    let searchParams = new URLSearchParams(params);

    url.search = searchParams;


    fetchWrapper(url, {
      method: "GET",
    })
      .then((data) => {
        if (data.location) {
          this.setState({
            userLocation: data.location,
            userLocationNotFound: false,
            zipcodeUpdating: false,
          });
          let newPrefs = {
            fuelCosts: data.location.regional_fuel_cost[0].gasoline,
          };
          this.updateUserPreferences(newPrefs);
          this.incentivePrefsModalToggle(false);
        } else {
          this.setState({
            userLocationNotFound: true,
            zipcodeUpdating: false,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.setState({
          userLocationNotFound: true,
          zipcodeUpdating: false,
        });
      });
  }

  updateUserPreferences(newPrefs) {
    let prefs = Object.assign({}, this.state.userPreferences, newPrefs);
    let newState = {
      userPreferences: prefs,
    };
    if (
      newPrefs.zipcode &&
      this.state.userPreferences.zipcode !== newPrefs.zipcode
    )
      newState.zipcodeUpdating = true;
    this.setState(newState);
  }

  incentivePrefsModalToggle(override) {
    if (typeof override === "boolean") {
      this.setState({ incentivePrefsModalIsOpen: override });
    } else {
      this.setState({
        incentivePrefsModalIsOpen: !this.state.incentivePrefsModalIsOpen,
      });
    }
  }

  render() {
    const ip = this.state.ipData ? this.state.ipData.ip : null;
    const uuid = this.state.uuid;

    const userPrefs = {
      get: (key) => getUserPref(key, this.state.userPreferences),
      getPreset: (key) => USER_PREF_PRESETS[key],
      set: this.updateUserPreferences,
      zipcodeIsNotFound: this.state.userLocationNotFound,
      zipcodeIsUpdating: this.state.zipcodeUpdating,
      showIncentivePrefsModal: this.state.incentivePrefsModalIsOpen,
      fuelCosts: this.state.userPreferences.fuelCosts,
      electricityCosts: this.state.userPreferences.electricityCosts,
      toggleMenu: this.toggleMenu,
      toggleIncentivePrefsModal: () => {
       this.incentivePrefsModalToggle();
       this.loadIncentivesData();
      },
      syncWorkingZipcode: () => {
        this.updateUserPreferences({
          zipcode: getUserPref("workingZipcode", this.state.userPreferences),
        });
      },
    };

    return (
      <UserPrefsContext.Provider value={userPrefs}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <>
                {this.state.errors.length > 0
                  ? this.state.errors.map((error, index) =>
                      error ? (
                        <Alert key={index} severity="error">
                          {error}
                        </Alert>
                      ) : null
                    )
                  : null}
                <PageLayout
                  props={props}
                  ip={ip}
                  page="Electric Vehicle Incentives | GreenCars"
                  description="Find out which rebates, tax credits and other benefits you qualify for when purchasing a new EV."
                  disclaimers="Listed incentives may not be available at any given time. Listed incentives reflect an illustrative estimation of available incentives. {process.env.REACT_APP_FULL_COMPANY_NAME} does not recommend or endorse any particular automotive or insurance company."
                  image={incentivesScreenshot}
                >
                  <Incentives
                    {...props}
                    electricVehicles={this.state.electricVehicles}
                    usedElectricVehicles={this.state.usedElectricVehicles}
                    incentives={this.state.incentives}
                    ip={ip}
                    uuid={uuid}
                    zipcode={userPrefs.get("zipcode")}
                  />
                </PageLayout>
              </>
            )}
          />
          <Route component={FourOhFour} />
        </Switch>
      </UserPrefsContext.Provider>
    );
  }
}

export default withRouter(Root);
