import fetchWrapper from "../utils/fetch/fetchWrapper";
const fetchVehicleIncentivesWithHandle = async params => {
       Object.keys(params).forEach(key => params[key] == null && delete params[key]);
       let url = new URL(`${process.env.REACT_APP_API_HOST}/vehicles/${params['vehicle_handle']}`);
       delete params['vehicle_handle'];
       let searchParams = new URLSearchParams(params);
       url.search = searchParams;
       url.search += `&include_used_vehicles`

       const json = await fetchWrapper(url, {
         method: "GET",
       });
       const incentives = json.vehicle.incentives;
       const filteredIncentives = params.turn_in_clunker ? incentives : incentives.filter(incentive => !incentive.limitations?.includes("turn in clunker"));

       return filteredIncentives;
};

export default fetchVehicleIncentivesWithHandle;
